import React from 'react'

const Faq = () => (
  <div id="faq">
    <div className="container">
      <div className="row" style={{marginTop: "64px"}}>
        <div className="col-12">
          <div className="section-title text-center">
            <h2>FAQ</h2>
          </div>
        </div>

        <div className="col-12">
          <h4>Can we bring our children?</h4>
          <p>We want to keep the ceremony on Saturday adults-only. If you do not have the possibility to come without children, please get in touch with us and we will find a solution!</p>
          
          <h4>Are there places to park at the chateau?</h4>
          <p>Yes, there is plenty of space to park at Chateau Martinay.</p>
          
          <h4>Do I need a car?</h4>
          <p>We recommend to have a car as the venue is located on the country side. On the Saturday we are planning to have some shuttles to bring you from your accommodation to the venue and back. Please get in touch if you are unsure how to get to our wedding. We will try to organize car pooling.</p>
          
          <h4>What is the dress code?</h4>
          <p><strong>Friday evening:</strong> smart casual</p>
          <p>
            <strong>Saturday:</strong> cocktail attire in light summer colours <br />
            Think about linen suits, chinos and jacket and light dresses. <br />
            We are hoping for warm and sunny weather, so do not forget your sunglasses and SPF.
          </p>

          <p><strong>Sunday:</strong> resort casual. Since we will relax next to the pool you can also bring your swimwear and a towel.</p>

        </div>
      </div>
    </div>
  </div>
)

export default Faq
