import React from 'react';
import Router from './Router'
import {BrowserRouter} from "react-router-dom"
import { ToastContainer } from 'react-toastify';
import './App.css';

import 'react-toastify/dist/ReactToastify.min.css';

const App = () => (
  <div className="App">
    <ToastContainer />
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </div>      
)

export default App;
