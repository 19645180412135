import {Routes, Route} from 'react-router-dom'
import Homepage from '../HomePage'
// import Homepage2 from '../HomePage2'
// import Homepage3 from '../HomePage3'
// import Homepage4 from '../HomePage4'
// import BlogPage from '../blogpage'
// import BlogDetailsPage from '../BlogDetailsPage'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import {Login, LOCAL_STORAGE_PASSWORD_KEY, checkPassword} from './Login'
import { useState } from 'react'
import useQueryParams from './useQueryParams'

const getRoutes = () => (
  <Routes>
    <Route exact path='/' element={<Homepage />} />
    {/* <Route path='/home' element={<Homepage />} />
    <Route path='/home2' element={<Homepage2 />} />
    <Route path='/home3' element={<Homepage3 />} />
    <Route path='/home4' element={<Homepage4 />} />
    <Route path='/Blog' element={<BlogPage />} />
    <Route path='/Blog-details' element={<BlogDetailsPage />} /> */}
  </Routes>
)

const Router = () => {
  const {pwd: query_password} = useQueryParams()
  const [stored_password, setStoredPassword] = useState(window.localStorage.getItem(LOCAL_STORAGE_PASSWORD_KEY) || query_password)

  if (query_password) {
    window.localStorage.setItem(LOCAL_STORAGE_PASSWORD_KEY, query_password)
    window.location.href = `${window.location.origin}${window.location.pathname}`
  }

  const is_signed_in = checkPassword(stored_password)

  if (!is_signed_in) return <Login setStoredPassword={setStoredPassword}/>

  return (
    <>
      {getRoutes()}
      <Footer/>
      <Scrollbar/>
    </>
  )
}

export default Router