import React, { useState } from 'react';
import CryptoJS from 'crypto-js';

export const LOCAL_STORAGE_PASSWORD_KEY = "MAGIC_PASSWORD"
const PASSWORD_SHA1 = "34267389e5d536c7b37a26c5d0eadf635dcf4530"

export const checkPassword = (password) => CryptoJS.SHA1(password).toString() === PASSWORD_SHA1

export const Login = ({setStoredPassword}) => {
  const [form_password, setFormPassword] = useState("")
  const [error, setError] = useState(false)
  const resetError = () => setError(false)

  const submitPassword = () => {
    if (checkPassword(form_password)) {
      window.localStorage.setItem(LOCAL_STORAGE_PASSWORD_KEY, form_password)
      return setStoredPassword(form_password)
    }
    setStoredPassword("")
    setFormPassword("")
    return setError(true)
  }

  const updateFormPassword = e => {
    setFormPassword(e.target.value)
    resetError()
  }

  return (
    <div id="home" className="item1" style={{height: "100vh"}}>
      <div className="container">
        <div className="slide-content">
          <div className="row">
            <div className="col-12">
              <div className="section-sub"></div>
              <h2 className="big"><span>What is the password ?</span></h2>
            </div>
            <div className="col-12">
              <div className="contact-form form-style">
                <strong style={{fontSize: "16px", marginBottom: "0px", minHeight: "24px", color: "red"}}>{error && "Wrong password - You can use the QR Code on the invitation"}</strong>
                <input type="text" value={form_password} onChange={updateFormPassword}/>
                <button className="cta-login" onClick={submitPassword}>ACCESS THE WEBSITE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
