import React from 'react'
import './style.css'

const Couple = () => {
    return(
        <div id="couple" className="about-wrap">
            <div className="couple-area section-padding pb-70">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 offset-lg-4 couple-single">
                        <div className="couple-wrap couple-wrap-2">
                            <div className="couple-img">
                                <img src="images/Couple_Picture.jpeg" alt="thumb"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Couple;