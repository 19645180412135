import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'
import banner from '../../images/header/banner.svg'

const SimpleSlider = () => (
  <div id="home">
    <div style={{position: "relative", top: "75px", backgroundColor: "#003f7f"}}>
      <img src={banner} alt="Merindol" style={{maxWidth: "100%", objectFit: "contain"}}/>
    </div>
  </div>
)

export default SimpleSlider;