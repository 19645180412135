import React from 'react'

import './style.css'
import Sectiontitle from '../section-title';

const Accomodations = () => {
    return(
        <>
        <div id="accomodations" className="section-padding">
            <div className="container">
                <Sectiontitle section={'Accomodations'}/>
                    
                <div className="map-title">
                    <p>There are a variety of accommodation options in the surrounding area including hotels, Bed&Breakfasts and AirBnBs. Please find some recommendations close to our venue below.</p>

                    <div>
                        <strong><a href="https://www.hotel-lhermitage.com/">Hôtel L’Hermitage ★★★</a></strong>
                        <p>
                            Hôtel L'Hermitage is a 19th-century property, located in Pernes-les-Fontaines. <br />
                            If more than 10 rooms are booked, you will receive a little discount. Please book directly on the hotel's website.
                        </p>
                    </div>
                    
                    <div>
                        <strong><a href="https://www.chateaudemazan.com/en">Château de Mazan, Best Western Premier Collection ★★★★</a></strong>
                        <p>In the heart of Provence, this charming hotel is nestled at the foot of Mont Ventoux in the picturesque village of Mazan.</p>
                    </div>

                    <div>
                        <strong><a href="https://en.le-comtadin.com/">Best Western Hotel Le Comtadin ★★★</a></strong>
                        <p>
                        Hotel Le Comtadin, at just a few steps from the historical centre of Carpentras and the famous truffle market, is a 3-star hotel set at the heart of a former 18th century private mansion.<br />
                        </p>
                    </div>
                </div>
        
                <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <iframe title="map" src="https://www.google.com/maps/d/u/0/embed?mid=1xrfD1wx9T89aZ3AN3Uhwwlk1IFVEa78&ehbc=2E312F" width="1080" height="840"></iframe>
                </div>
            </div>
        </div>
        <div id="travel">
            <div className="container">
                <Sectiontitle section={'Travel'}/>
                    
                <strong>Marseille Provence Airport</strong>
                <p>
                    <strong>Flight</strong> <br />
                    At 1 hour by car to Château Martinay. Possibility to rent a car.
                </p>

                <strong>Avignon Train Station</strong>
                <p>
                    <strong>Train</strong> <br />
                    At 35 minutes by car to Château Martinay. Possibility to rent a car.
                </p>
            </div>
        </div>
        </>
    )
}
export default Accomodations;