import React from  'react';

// components
import Couple from '../../components/couple';
import SimpleSlider from '../../components/hero';
// import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
// import People from '../../components/people';
import Map from '../../components/map';
import Location from '../../components/location';
// import Gallery from '../../components/gallery';
import Rsvp from '../../components/rsvp';
import Gift from '../../components/gift';
import Navbar from '../../components/Navbar'
import Saveday from '../../components/countdown'
import Accomodations from '../../components/accomodations';
import Faq from '../../components/faq';


const Homepage = () => {
    return(
       <div>
           <Navbar/>
           <SimpleSlider/>
           <Welcome/>
           <Saveday/>
           {/* <Story/> */}
           {/* <People/> */}
           <Map />
           <Location/>
           <Accomodations/>
           {/* <Gallery/> */}
           <Rsvp/>
           <Faq/>
           <Gift/>
           <Couple/>
       </div>
    )
}

export default Homepage;